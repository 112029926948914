<!--
 * @Author: dsj
 * @LastEditors: dsj
 * @Date: 2022-11-15 17:49:20
 * @LastEditTime: 2023-06-19 14:57:10
 * @Description: TO DO
-->
<template>
	<div class="compose-type-wrap">
		<div class="page-banner"></div>
		<div class="article-content">
			<h3>Finpay供应链金融云服务平台</h3>
			<p>
				是我司于2017年开发建设并独立运营的金融科技云服务平台，自开始运营至今，平台已实现对多个产业链的全生产环节覆盖，并接入多家金融机构及资产服务供应商，为实体制造企业提供全周期、全环节、全流程的专业服务。
				<el-image
					:src="require('@/assets/image/jbb/1.png')"
					:zoom-rate="1.2"
					:preview-src-list="[require('@/assets/image/jbb/1.png')]"
					:initial-index="4"
					fit="cover"
				/>
				<el-image
					:src="require('@/assets/image/jbb/2.png')"
					:zoom-rate="1.2"
					:preview-src-list="[require('@/assets/image/jbb/2.png')]"
					:initial-index="4"
					fit="cover"
				/>
			</p>
		</div>

		<section class="login-entry">
			<div class="login-box">
				<img class="logo" :src="require('@/assets/image/composeType/jbp/logo.png')" />
				<h3>金保PAY平台</h3>
				<p>成为中国领先额金融科技公司是我们不懈的追求</p>
				<img class="btn" :src="require('@/assets/image/composeType/jbp/btn.png')" @click="toJbbLogin" />
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'FinpayPage',
	data() {
		return {}
	},
	created() {},
	watch: {},
	methods: {
		toJbbLogin() {
			window.open('https://finpay.wqfinfac.com/#/login')
		},
	},
	components: {},
}
</script>

<style scoped lang="scss">
.page-banner {
	background: url('~@/assets/image/composeType/jbp/banner.png') center center no-repeat,
		linear-gradient(to right, #f0573d, #e6322e);
}

.login-entry {
	// height: 287px;
	background: url('~@/assets/image/composeType/jbp/bg.png') center center no-repeat;
	background-size: cover;
	color: #846e2e;
	padding: 40px 0;
	.login-box {
		width: 1000px;
		margin: 0 auto;
		h3 {
			font-size: 44px;
		}
		p {
			font-size: 18px;
		}
		img {
			display: block;
		}
		.logo {
			width: 93px;
			margin-bottom: 20px;
		}
		.btn {
			cursor: pointer;
			border-radius: 80px;
			width: 250px;
			margin-top: 50px;
			&:hover {
				box-shadow: 0px 2px 24px 0px rgba(241, 173, 69, 0.29);
			}
		}
		@media screen and (max-width: 500px) {
			width: 90%;
			h3 {
				font-size: 32px;
			}
			p {
				font-size: 14px;
			}
		}
	}
}
</style>
