/*
 * @Author: dsj
 * @LastEditors: dsj
 * @Date: 2022-11-15 17:49:13
 * @LastEditTime: 2023-06-19 15:01:44
 * @Description: TO DO
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import Home from "@/components/Home";
import FinpayPage from "@/components/FinpayPage";
const AboutUs = () => import('@/components/AboutUs')
const NewsInfo = () => import('@/components/NewsInfo')
const NewsDetail = () => import('@/components/NewsDetail')
const ComposeType = () => import('@/components/ComposeType')
const TechService = () => import('@/components/TechService')
const Rzzl = () => import('@/components/Rzzl')
const Gkscfx = () => import('@/components/Gkscfx')
const JbPay = () => import('@/components/JbPay')

// const routerHistory = createWebHistory()
const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            component: Home
        },
        {
            path: '/finpaypage',
            component: FinpayPage
        },
        {
            path: '/aboutUs',
            component: AboutUs
        },
        {
            path: '/newsInfo',
            component: NewsInfo
        },
        {
            path: '/newsDetail',
            component: NewsDetail
        }, {
            path: '/composeType',
            component: ComposeType,
        }, { //科技服务
            path: '/techService',
            component: TechService,
        }, { //融资租赁
            path: '/rzzl',
            component: Rzzl,
        }, { //公开市场发行 (保理资产证券化业务
            path: '/gkscfx',
            component: Gkscfx,
        }, { //金保PAY
            path: '/jbPay',
            component: JbPay,
        }
    ]
})

export default router