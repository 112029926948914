
export default {
    // 开启命名空间
    namespaced: true,
    state: {
        displayWidth: 1200,
    },
    mutations: {
        updateFirstDisplay(state, val) {
            state.displayWidth = val
        }
    },
    actions: {},
    getters: {
        menuActive(state) {
            return state.displayWidth >= 800;
        },
        firstPageDisplay(state) {
            return state.displayWidth >= 1120;
        }
    }
}