import { createStore } from 'vuex'

import global from './modules/global.js'
import windowSize from './modules/windowsize.js'
import createPersistedstate from 'vuex-persistedstate'

export default createStore({
    // 公共模板直接在这里展开就可以
    ...global,
    modules: {
        windowSize
    },
    plugins: [
        createPersistedstate({
            storage: window.sessionStorage,
            reducer(val) {
                return {
                    path: val.path
                }
            }
        })
    ]
})