<template>
	<div>
		<!-- 轮播图 -->
		<el-carousel :autoplay="false">
			<el-carousel-item>
				<div class="banner-1 banner">
					<div class="banner-content">
						<h3>M+1+N供应链</h3>
						<p>
							根据核心企业、供应商及采购商各自的实际需求，特别是现金流、账期、销售等方面需求，公司在不改变核心企业与上下游之间贸易关系的基础上，结合各方的信用情况，为客户提供高效、便捷的金融产品。
						</p>
						<el-button class="jbb-btn" @click="$router.push({ path: '/composeType' })">查看详情</el-button>
					</div>
				</div>
			</el-carousel-item>

			<el-carousel-item>
				<div class="banner-2 banner">
					<div class="banner-content">
						<h3>融资租赁业务</h3>
						<p>
							以融资租赁的方式为客户提供新设备采买、存量固定资产售后回租等模式的融资。客户可根据自身经营情况，在新设备采购中选择直租模式，获得符合建设周期的融资；或以存量固定资产开展售后回租，获得稳定的中长期负债。
						</p>
						<el-button class="jbb-btn" @click="$router.push({ path: '/rzzl' })">查看详情</el-button>
					</div>
				</div>
			</el-carousel-item>

			<el-carousel-item>
				<div class="banner-3 banner">
					<div class="banner-content right-content">
						<h3>保理资产证券化</h3>
						<p>
							将不同客户对核心企业或同一产业链的应收账款按金额、账期等方式进行组包，并在交易所或银行间市场进行发行，从而将资产对接投资人以获取更低的融资成本。以保理公司为纽带，连接资产与资金，实现产业与资本的互联互通。实现银行机构、资产服务机构、审计及法务机构的接入，为平台资产证券化业务实现一键打包、一键完成全流程工作。
						</p>
						<el-button class="jbb-btn" @click="$router.push({ path: '/gkscfx' })">查看详情</el-button>
					</div>
				</div>
			</el-carousel-item>

			<el-carousel-item>
				<div class="banner-4 banner">
					<div class="banner-content right-content">
						<h3>金保pay业务</h3>
						<p>金保pay业务是一款服务于大型企业（简称“核心企业”）及其上游供应链的供应链金融产品。</p>
						<el-button class="jbb-btn" @click="$router.push({ path: '/jbPay' })">查看详情</el-button>
					</div>
				</div>
			</el-carousel-item>

			<!-- <el-carousel-item>
				<div class="banner-5 banner"></div>
			</el-carousel-item> -->
		</el-carousel>

		<!-- 产业融资 -->
		<section class="industrial-finance">
			<h3 class="industrial-finance-title">
				<p>Industrial Finance</p>
				<p>产业融资</p>
			</h3>
			<div class="fin-item-wrap">
				<div class="industrial-finance-item gyl" @click="toPage('ComposeType')">
					<p class="title">M+1+N 供应链</p>
					<div class="detail">
						<p>M+1+N 供应链</p>
						根据核心企业、供应商及采购商各自的实际需求，特别是现金流、账期、销售等方面需求，公司在不改变核心企业与上下游之间贸易关系的基础上，结合各方的信用情况，为客户提供高效、便捷的金融产品。
					</div>
				</div>
				<div class="industrial-finance-item rzzl" @click="toPage('rzzl')">
					<p class="title">融资租赁</p>
					<div class="detail">
						<p>融资租赁</p>
						以融资租赁的方式为客户提供新设备采买、存量固定资产售后回租等模式的融资。客户可根据自身经营情况，在新设备采购中选择直租模式，获得符合建设周期的融资；或以存量固定资产开展售后回租，获得稳定的中长期负债。
					</div>
				</div>
				<div class="industrial-finance-item gkscfx" @click="toPage('gkscfx')">
					<p class="title">公开市场发行</p>
					<div class="detail">
						<p>公开市场发行</p>
						将不同客户对核心企业或同一产业链的应收账款按金额、账期等方式进行组包，并在交易所或银行间市场进行发行，从而将资产对接投资人以获取更低的融资成本。以保理公司为纽带，连接资产与资金，实现产业与资本的互联互通。实现银行机构、资产服务机构、审计及法务机构的接入，为平台资产证券化业务实现一键打包、一键完成全流程工作。
					</div>
				</div>
				<div class="industrial-finance-item jbp" @click="toPage('jbPay')">
					<p class="title">金保pay</p>
					<div class="detail">
						<p>金保pay</p>
						以核心企业（买方）的多个上游供应商（卖方）的应付账款为基础，为核心企业提供的集中应付账款管理，为供应商提供流转、融资、账款收取等服务于一体的综合性模式化保理服务。
					</div>
				</div>
				<br />
			</div>
		</section>

		<!-- 数据平台 -->
		<section class="hp-platform">
			<p class="hp-cloud" ref="pLeft" @mouseenter.stop="addAnimate('Left')" @mouseleave="removeAnimate('Right')">
				<span>科技服务</span>
				<span>
					<!-- Hp- <br />
					Cloud开发 <br />平台 -->
					统一<br />开发<br />平台
				</span>
				<span>
					<!-- Hp-Cloud <br />
					development <br /> -->
					Unified Development <br />Platform
				</span>
			</p>
			<div class="plat-img-content">
				<div class="hp-cloud-content content animated" ref="elLeft">
					<p>科技服务</p>
					<!-- <p>HP-Cloud开发</p> -->
					<p>统一开发平台</p>
					<p>开发平台实现了开发一次即可跨平台部署、赋能业务部门。</p>
					<el-button size="large" class="jbb-btn" @click="$router.push({ path: '/TechService' })"
						>查看详情</el-button
					>
				</div>
				<div class="big-data-content content animated" ref="elRight">
					<p>科技服务</p>
					<p>大数据平台</p>
					<p>行业大数据平台通过对行业内各项宏观和微观数据的采集、清洗、存储、管理和分析全流程数据化管理。</p>
					<el-button size="large" class="jbb-btn" @click="$router.push({ path: '/TechService' })"
						>查看详情</el-button
					>
				</div>
			</div>
			<p class="big-data" ref="pRight" @mouseenter="addAnimate('Right')" @mouseleave="removeAnimate('Left')">
				<span>大数据</span>
				<span> 大数据 <br />平台 </span>
				<span>
					Big Data <br />
					platform
				</span>
			</p>
		</section>

		<!-- 金宝贝 -->
		<section class="jbb-platform">
			<div class="jbb-login-box">
				<h3>金保Pay平台</h3>
				<p>
					金保贝是核心企业依托其产业链优势,利用电子签名、发票识别、发票验真等技术手段,将其优质企业信用转化为可拆分、可流转、可融资的创新金融服务共享平台。其目的在协助上下游客户缓解资金压力的同时，助力金融机构获得稳定的客群和优质的金融资产。
				</p>
				<div class="btn-row">
					<el-button id="more" size="large" class="jbb-btn" @click="toJbbMore">更多介绍</el-button>
					<el-button size="large" class="jbb-btn" @click="toJbbLogin">登录</el-button>
					<el-button size="large" class="jbb-btn" @click="toJbbReg">注册</el-button>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'FirstPage',
	data() {
		return {}
	},
	methods: {
		addAnimate(dr) {
			let opp = {
				Left: 'Right',
				Right: 'Left',
			}
			this.$refs[`el${dr}`].style.display = 'block'
			this.$refs[`el${opp[dr]}`].style.display = 'none'
			this.$refs[`el${dr}`].classList.add(`fadeIn${dr}`)
			this.$refs[`p${dr}`].classList.add(`to-red`)
			this.$refs[`p${opp[dr]}`].classList.remove(`to-red`)
		},
		removeAnimate(dr) {
			this.$refs[`el${dr}`].classList.remove(`fadeIn${dr}`)
		},
		toJbbLogin() {
			window.open('https://finpay.wqfinfac.com/#/login')
		},
		toJbbReg() {
			window.open('https://finpay.wqfinfac.com/#/register/registerUser')
		},
		toJbbMore() {
			this.$router.push({
				path: '/FinpayPage',
			})
		},
		toPage(path) {
			this.$router.push({
				path,
			})
		},
	},
	created() {},
	mounted() {},
}
</script>

<style lang="scss" scoped>
/* 轮播图 */

.el-carousel ::v-deep .el-carousel__container {
	height: 360px;
	.el-carousel__item {
		width: 100%;
		&:nth-child(2n) {
			background-color: #99a9bf;
			text-align: center;
		}
		&:nth-child(2n + 1) {
			text-align: center;
			background-color: #d3dce6;
		}
		.banner {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			@media screen and (max-width: 500px) {
				background-size: cover;
				background-position: 65% 50%;
			}
		}
	}
	.banner-1 {
		background: url('~@/assets/image/home/banner1.png') center center no-repeat,
			linear-gradient(to right, #760006, #e36427);
	}
	.banner-2 {
		background: url('~@/assets/image/home/banner2.jpg');
		background-size: cover;
	}
	.banner-3 {
		background: url('~@/assets/image/home/banner3.jpg');
		background-size: cover;
	}
	.banner-4 {
		background: url('~@/assets/image/home/banner4.jpg');
		background-size: cover;
	}
	.banner-5 {
		background: url('~@/assets/image/home/banner5.jpg');
		background-size: cover;
	}
	.banner-content {
		display: block;
		color: #fff;
		text-align: left;
		width: 423px;
		margin-left: -500px;
		&.right-content {
			margin-left: 700px;
		}
		@media screen and (max-width: 500px) {
			width: 50%;
			margin-left: -150px !important;
		}
		h3 {
			@media screen and (max-width: 500px) {
				font-size: 30px;
			}
			font-size: 47px;
			font-weight: normal;
			white-space: nowrap;
		}
		p {
			@media screen and (max-width: 500px) {
				font-size: 12px;
			}
			margin: 21px 0;
			font-size: 16px;
			line-height: 1.5em;
		}
	}
}
.el-carousel--horizontal ::v-deep .el-carousel__indicator {
	button {
		width: 8px;
		height: 8px;
		background: #fff;
		border-radius: 50%;
		opacity: 0.5;
	}
	&.is-active button {
		opacity: 1;
	}
}
/* 产业融资 */
.industrial-finance {
	text-align: center;
	margin: 70px 0 100px;
	@media screen and (max-width: 500px) {
		margin-top: 30px;
		margin-bottom: 50px;
	}
	.industrial-finance-title {
		p:last-of-type {
			margin-bottom: 25px;
			position: relative;
			font-size: 28px;
			font-weight: normal;
			color: #df2325;
			letter-spacing: 5px;
		}
		p:first-of-type {
			font-size: 36px;
			color: #e3e3e3;
			font-weight: normal;
			opacity: 0.5;
			letter-spacing: 0;
			margin-bottom: -30px;
		}
		@media screen and (max-width: 500px) {
			p:last-of-type {
				font-size: 22px;
			}
			p:first-of-type {
				font-size: 30px;
				margin-bottom: -22px;
			}
		}
	}
	.fin-item-wrap {
		width: 1200px;
		display: flex;
		flex-wrap: wrap;
		margin: 0 auto;
		justify-content: center;
		.industrial-finance-item {
			margin: 10px;
			width: calc(50% - 20px);
			height: 280px;
			font-size: 32px;
			cursor: pointer;
			background-size: cover;
			background-repeat: no-repeat;
			transition: 1s;
			&:hover {
				background-color: #760006;
			}
			&:hover {
				.title {
					display: none;
				}
				.detail {
					display: block;
				}
			}
			.title {
				font-size: 32px;
				font-weight: normal;
				text-align: left;
				color: #8f721c;
				margin: 100px 0 0 40px;
				display: block;
			}
			.detail {
				padding: 54px 0 0 40px;
				font-size: 16px;
				color: #fff;
				line-height: 22px;
				text-align: left;
				width: 325px;
				display: none;
				p {
					font-size: 28px;
					margin-bottom: 29px;
				}
			}
			&.gyl {
				background-image: url('~@/assets/image/industrial-finance/gyl.png');
				&:hover {
					background-image: url('~@/assets/image/industrial-finance/gyl1.png');
				}
			}
			&.rzzl {
				background-image: url('~@/assets/image/industrial-finance/rzzl.png');
				&:hover {
					background-image: url('~@/assets/image/industrial-finance/rzzl1.png');
				}
			}
			&.gkscfx {
				background-image: url('~@/assets/image/industrial-finance/gkscfx.png');
				&:hover {
					background-image: url('~@/assets/image/industrial-finance/gkscfx1.png');
				}
			}
			&.jbp {
				background-image: url('~@/assets/image/industrial-finance/jbp.png');
				&:hover {
					background-image: url('~@/assets/image/industrial-finance/jbp1.png');
				}
			}
		}
		@media screen and (max-width: 500px) {
			width: 100%;
			box-sizing: border-box;
			.industrial-finance-item {
				margin: 5px;
				font-size: 14px;
				height: 95px;
				pointer-events: none;
				background-position: 50% 50%;
				.title {
					margin: 20px 13px;
					font-size: 15px;
				}
			}
		}
	}
}

/* 数据平台 */
.hp-platform {
	display: flex;
	justify-content: center;
	margin: 0 auto 100px;
	width: calc(1200px - 20px);
	> p {
		font-size: 30px;
		width: 220px;
		background: linear-gradient(134deg, #f9f6ef 0%, #f1ebdc 100%);
		opacity: 0.7;
		color: #fff;
		box-sizing: border-box;
		cursor: pointer;
		word-break: break-all;
		color: #8f721c;
		padding-top: 53px;
		// &:hover {
		// 	background: rgba(168, 15, 17, 1);
		// 	color: #fff;
		// }
		&.to-red {
			background: rgba(168, 15, 17, 1);
			color: #fff;
		}

		span {
			margin: 0 auto;
			width: 107px;
			font-size: 20px;
			text-align: left;
			display: block;
			&:nth-of-type(2) {
				font-size: 30px;
				margin: 3px auto 20px;
			}
			&:nth-of-type(3) {
				font-size: 16px;
			}
		}
	}

	.plat-img-content {
		width: 100%;
		margin: 0;
		padding: 30px 40px;
		height: 395px;
		background-image: url('~@/assets/image/home/hp-platform.png');
		background-size: cover;
		color: #fff;
		font-size: 16px;
		box-sizing: border-box;
		.content {
			height: 335px;
			width: calc(50% - 48px);
			box-sizing: border-box;
			display: block;
			background: rgba(168, 15, 17, 0.7);
			padding: 30px 48px;
			transition: all 0.5s; //默认 transition
			p {
				line-height: 1.5em;
			}
			p:first-of-type {
				font-size: 20px;
			}
			p:nth-of-type(2) {
				font-size: 36px;
				margin-bottom: 15px;
			}
			.jbb-btn {
				margin-top: 30px;
			}
			&.hp-cloud-content {
				float: left;
			}
			&.big-data-content {
				float: right;
				display: none;
			}
		}
	}
	@media screen and (max-width: 500px) {
		width: calc(100% - 40px);
		margin: 20px 20px 50px;
		> p {
			display: none;
		}
		.plat-img-content {
			margin: 0;
			height: auto;
			padding: 20px;
			.content {
				padding: 20px;
				width: 100%;
				font-size: 12px;
				height: auto;
				p:first-of-type {
					font-size: 15px;
				}
				p:nth-of-type(2) {
					font-size: 22px;
					margin-bottom: 22px;
				}
				.el-button {
					display: none;
				}
				&.big-data-content {
					margin-top: 20px;
					display: block;
				}
			}
		}
	}
}

/* 金宝贝平台 */
.jbb-platform {
	padding: 50px 0;
	background: url('~@/assets/image/home/jbb-platform.png') center center no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	.jbb-login-box {
		width: calc(1200px - 20px);
		height: 287px;
		// opacity: 0.9;
		// backdrop-filter: blur(10px);
		text-align: center;
		color: #fff;
		background: transparent;
		// cursor: pointer;
		transition: 0.5s;
		.btn-row {
			opacity: 0;
			transition: 0.5s;
		}
		&:hover {
			background: rgba(168, 15, 17, 0.8);
			.btn-row {
				opacity: 1;
			}
		}
		h3 {
			margin-bottom: 20px;
			padding-top: 60px;
			font-size: 36px;
			line-height: 50px;
		}
		p {
			width: 764px;
			line-height: 1.5em;
			font-size: 16px;
			margin: 0 auto 28px;
		}
	}
	@media screen and (max-width: 500px) {
		padding: 40px 20px;
		background-size: cover;
		text-align: left;

		#more {
			display: none;
		}
		.jbb-login-box {
			width: 100%;
			height: auto;
			padding: 20px 40px 30px;
			text-align: left;
			background: rgba(168, 15, 17, 0.8);
			&:hover {
			}
			h3 {
				font-size: 24px;
				margin: 0;
				padding: 0;
			}
			p {
				margin: 0;
				width: auto;
				font-size: 12px;
			}
			.btn-row {
				opacity: 1;
			}
			.el-button {
				margin: 10px 10px 0 0;
			}
		}
	}
}
</style>