<template>
	<div class="main-app">
		<div class="header-container" :class="{ 'vertical-header': mobileMenuShow, 'menu-opend': mobileMenuOpen }">
			<img class="logo" src="../assets/image/logo_wq.png" />
			<el-icon size="32px" color="#1D232E" @click="mobileMenuOpen = true"><Fold /></el-icon>
			<div class="menu-wrap" @click="mobileMenuOpen = false">
				<el-menu
					:default-active="activeMenu"
					:mode="screenWidth > 500 ? 'horizontal' : 'vertical'"
					@select="handleSelect"
					@click.stop="() => {}"
					router
					:ellipsis="false"
				>
					<el-menu-item index="/">首 页</el-menu-item>
					<el-sub-menu index="2" popper-class="wqjb-popper-menu">
						<template #title>产业融资</template>
						<el-menu-item index="/ComposeType">M+1+N供应链</el-menu-item>
						<el-menu-item index="/rzzl">融资租赁</el-menu-item>
						<el-menu-item index="/gkscfx">公开市场发行</el-menu-item>
						<el-menu-item index="/jbPay">金保PAY</el-menu-item>
					</el-sub-menu>
					<el-menu-item index="/TechService">科技服务</el-menu-item>
					<el-menu-item index="/FinpayPage">金保Pay平台</el-menu-item>
					<el-menu-item index="/NewsInfo">新闻资讯</el-menu-item>
					<el-menu-item index="/AboutUs">关于我们</el-menu-item>
				</el-menu>
			</div>
		</div>

		<main>
			<router-view />

			<!-- 底部 -->
			<footer class="main-footer clearfix">
				<div>
					<div class="content">
						<h3>魏桥产融</h3>
						<el-link style="visibility:hidden">魏桥金保</el-link>
						<!-- <el-link>宏鹏数字科技</el-link>
						<el-link>宏桥商业保理</el-link> -->
					</div>
					<div class="content">
						<h3>快速入口</h3>
						<el-link href="https://finpay.wqfinfac.com/#/login">金保Pay</el-link>
						<!-- <el-link>大数据平台</el-link>
						<el-link>Hp-Cloud开发平台</el-link> -->
					</div>

					<div class="qr-box">
						<img class="qrcode" src="@/assets/image/con_eq_n_blank.png" />
						<p>扫一扫关注<br />深圳宏鹏公众号</p>
					</div>
					<div class="qr-box">
						<img class="qrcode" src="@/assets/image/con_eq_n_blank.png" />
						<p>扫一扫关注<br />魏桥金保公众号</p>
					</div>
				</div>

				<div class="copy-right">
					<a>Copyright @ 重庆魏桥金融保理有限公司 2023 版权所有 </a> |
					<a href="https://beian.miit.gov.cn">渝ICP备17000428号</a>
				</div>
			</footer>
		</main>
	</div>
</template>

<script>
export default {
	name: 'MainIndex',
	data() {
		return {
			path: '/',
			menuActive: Boolean,
			display: true,
			screenWidth: document.body.clientWidth,
			mobileMenuOpen: false, //手机端菜单打开
		}
	},
	computed: {
		mobileMenuShow() {
			//显示手机菜单
			return this.screenWidth < 500
		},
		activeMenu() {
			const route = this.$route
			const { meta, path } = route
			// if set path, the sidebar will highlight the path you set
			console.log(meta, path)
			// if (meta.activeMenu) {
			// 	return meta.activeMenu
			// }
			return path
		},
	},
	methods: {
		handleSelect() {
			this.mobileMenuOpen = false
		},
		onCommand(command) {
			console.log(command)
			this.$router.push(command)
		},
		eventHandler(e) {
			if (!this.mobileMenuOpen) return
			e.preventDefault()
		},
	},
	created() {
		this.screenWidth = document.body.clientWidth
		this.$store.commit('windowSize/updateFirstDisplay', this.screenWidth)
	},
	mounted() {
		document.body.addEventListener('touchmove', this.eventHandler, {
			passive: false,
		})
	},
	unmounted() {
		document.body.removeEventListener('touchmove', this.eventHandler, {
			passive: false,
		})
	},
}
</script>

<style lang="scss">
// el-menu 悬浮子菜单样式修改
.el-popper.wqjb-popper-menu {
	// display: block !important;
	left: 0px !important;
	width: 100%;
	border: none !important;
	margin-top: -5px !important;
	.el-menu.el-menu--popup {
		border: none;
		background: #99090b !important;
		width: 100%;
		padding: 0;
		display: flex;
		justify-content: center;
		background: transparent;
		.el-menu-item {
			padding: 38px 22px;
			background: transparent;
			color: #c2a54f;
			font-size: 16px;
			&.is-active {
				color: #ffce3e;
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.main-app {
	margin-top: -1px;
	border-top: 1px solid transparent;
}
// 手机菜单
.header-container.vertical-header {
	height: 79px;
	width: 100%;
	position: fixed;
	background-color: rgba(255, 255, 255, 0.95);
	z-index: 99;
	display: flex;
	justify-content: center;
	align-items: center;
	.logo {
		width: 140px;
		height: auto;
		display: block;
		margin: 0 auto;
	}
	.el-icon {
		display: block;
		position: absolute;
		top: 25px;
		right: 10px;
	}
	.el-menu {
		display: none;
	}
	.menu-wrap,
	.el-icon {
		transition: all 0.5s;
	}
	// 手机菜单展开
	&.menu-opend {
		.el-icon {
			transform: rotate(-90deg);
		}
		.menu-wrap {
			background: rgba(0, 0, 0, 0.7);
			justify-content: space-between;
			height: 100%;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			.logo {
				display: none;
			}
			.el-menu {
				width: 70%;
				left: 0;
				height: 100%;
				display: block;
			}
		}
	}
}
// 修改菜单样式
.header-container {
	.el-icon {
		display: none;
	}
	width: 100%;
	position: fixed;
	background-color: rgba(255, 255, 255, 0.95);
	box-shadow: 0px 0px 3px 1px #ccc;
	z-index: 2000;
	display: flex;
	justify-content: space-around;
	align-items: center;
	.logo {
		width: 140px;
		transform: scale(1.5);
	}
	.el-menu {
		border-bottom: none;
	}
	.el-menu--horizontal {
		::v-deep .el-menu-item {
			margin: 0 10px;
			padding: 10px 25px;
			height: 100%;
			font-size: 16px;
			border: none;
			&.is-active {
				background: #c39e5a !important;
				color: #ffffff !important;
				box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.3);
			}
			&:not(.is-disabled):hover {
				background: #c39e5a !important;
				color: #ffffff !important;
				box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.3);
			}
			&:focus {
				background: transparent;
				color: #303133;
			}
		}
		::v-deep .el-sub-menu {
			border: none;
			.el-icon {
				display: none;
			}
			&.is-active {
				.el-sub-menu__title {
					background: #c39e5a !important;
					color: #ffffff !important;
					box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.3);
				}
			}
			&:not(.is-active) .el-sub-menu__title:hover {
				background: #c39e5a !important;
				color: #ffffff !important;
				box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.3);
			}
			.el-sub-menu__title {
				background: transparent;
				padding: 10px 25px;
				height: 100%;
				font-size: 16px;
				border: none;
			}
		}
	}
}

main {
	width: 100%;
	margin-top: 76px;
}

.dropdown .el-dropdown-menu__item:not(.is-disabled):hover {
	background-color: #ffffff;
	color: #c39e5a;
}
.dropdown {
	background-color: #ffffff;
	color: #c39e5a;
}
.el-main {
	margin-top: 76px;
	padding: 0;
	min-height: 90%;
}
router-link {
	text-decoration: none;
}
/* 底部 */
.main-footer {
	background-color: #181617;
	padding: 45px 120px 37px;
	color: #ffffff;
	font-size: 14px;
	line-height: 2em;
	.content {
		display: inline-block;
		margin-right: 120px;
		h3 {
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 15px;
		}
		.el-link {
			display: block;
		}
	}
	.qr-box {
		float: right;
		line-height: 1.5em;
		font-size: 12px;
		text-align: center;
		margin-left: 57px;
		.qrcode {
			width: 88px;
			height: 88px;
			margin-bottom: 5px;
		}
	}
	.copy-right {
		margin-top: 49px;
		padding: 5px 0;
		border-top: 1px solid #272526;
		width: 100%;
		font-size: 12px;
		color: #606266;
		a {
			color: #606266;
		}
	}
	@media screen and (max-width: 500px) {
		padding: 30px 20px;
		.content {
			display: block;
			margin: 0;
			margin-bottom: 50px;
		}
		.qr-box {
			float: none;
			display: inline-block;
			margin: 0;
			margin-right: 30px;
			p {
				display: none;
			}
		}
	}
}
</style>
