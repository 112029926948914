// 全局
export default {
    state: {
        path: '/',
        new_info: ''
    },
    mutations: {
        updatePath(state, val) {
            state.path = val
        },
        updateNewInfo(state, val) {
            state.new_info = val
        }
    },
    actions: {},
    getters: {}
}