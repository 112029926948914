/*
 * @Author: dsj
 * @LastEditors: dsj
 * @Date: 2022-11-15 17:49:13
 * @LastEditTime: 2023-06-29 09:19:18
 * @Description: TO DO
 */
import { createApp } from 'vue'
import App from './App.vue'
import elementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import '@/assets/css/index.scss'
// import '@/assets/iconfont/iconfont.css'
import axios from 'axios'
import router from "@/router";
import store from "@/store";
import './assets/fonts/font.css'
import './assets/style/index.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import animated from 'animate.css'

const app = createApp(App);
var baseURL = '/'
if (process.env.NODE_ENV == 'production') baseURL = 'http://www.wqfinfac.com/website-admin' // 生产环境路径
const instance = axios.create({
    baseURL
})
instance.interceptors.request.use(config => {
    if (config.method == 'post') {
        let ret = ''
        for (let it in config.data) {
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(config.data[it]) + '&'
        }
        config.data = ret
    }
    return config
})
app.config.globalProperties.$axios = instance;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(animated).use(elementPlus).use(router).use(store).mount('#app');

