<!--
 * @Author: dsj
 * @LastEditors: dsj
 * @Date: 2022-11-15 17:49:13
 * @LastEditTime: 2023-04-12 13:55:43
 * @Description: TO DO
-->
<template>
	<index></index>
</template>

<script>
import Index from './components/Index'

export default {
	name: 'App',
	components: {
		Index,
	},
	created() {
		//在页面加载时读取sessionStorage里的状态信息
		if (sessionStorage.getItem('store')) {
			this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
		}

		//在页面刷新时将vuex里的信息保存到sessionStorage里
		window.addEventListener('beforeunload', () => {
			sessionStorage.setItem('store', JSON.stringify(this.$store.state))
		})
	},
}
</script>

<style>
body,
html {
    width: 100%;
    height: 100%;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 14px;
	line-height: 1.42857143;
	color: #333;
	background-color: #fff;
}
</style>
